<template>
  <div class="container" ref="container">
    <div ref="buttonOut">
      <el-form
        :model="queryParams"
        @keyup.enter.native="handlePage()"
        inline
        label-suffix="："
      >
        <el-form-item label="操作人">
          <el-input
            v-model="queryParams.operator"
            size="small"
            placeholder="请输入操作人"
            style="width: 140px"
          />
        </el-form-item>
        <el-form-item label="操作类型">
          <el-select
            v-model="queryParams.type"
            size="small"
            clearable
            placeholder="请选择操作类型"
            style="width: 140px"
          >
            <el-option label="全部" :value="-1" />
            <el-option label="新增" :value="0" />
            <el-option label="修改" :value="1" />
            <el-option label="删除" :value="2" />
            <el-option label="启停" :value="3" />
            <el-option label="添加单北斗设备号" :value="4" />
            <el-option label="修改单北斗设备号" :value="5" />
            <el-option label="清除单北斗设备号" :value="6" />
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input
            v-model="queryParams.carPlateNum"
            size="small"
            placeholder="请输入车牌号"
            style="width: 140px"
          />
        </el-form-item>
        <el-form-item label="车牌颜色">
          <el-input
            v-model="queryParams.carPlateColor"
            size="small"
            placeholder="请输入车牌颜色"
            style="width: 140px"
          />
        </el-form-item>
        <el-form-item label="设备号">
          <el-input
            v-model="queryParams.equipmentId"
            size="small"
            placeholder="请输入设备号"
            style="width: 140px"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            v-if="isAuth('equipmentoperation:list')"
            @click="handlePage()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" :height="tableHeight + 'px'">
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="operator"
        label="操作人"
        min-width="80"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="_type"
        label="操作类型"
        min-width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="carPlateNum"
        label="车牌号"
        min-width="140"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="carPlateColor"
        label="车牌颜色"
        min-width="80"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="equipmentId"
        label="设备号"
        min-width="140"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="操作说明"
        min-width="300"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="时间"
        min-width="160"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <el-pagination
      v-if="totalCount"
      @size-change="handlePageSize"
      @current-change="handlePage"
      :current-page="queryParams.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryParams.pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryParams: {
        operator: "",
        type: -1,
        carPlateNum: "",
        carPlateColor: "",
        equipmentId: "",
        pageNo: 1,
        pageSize: 20,
      },
      tableData: [],
      totalCount: 0,
      tableHeight: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight =
        this.$refs.container.clientHeight -
        this.$refs.buttonOut.clientHeight -
        // 分页高度32px + margin-top 15px
        (32 + 15);
    });
    this.handlePage();
  },
  methods: {
    handlePage(page = 1) {
      this.queryParams.pageNo = page;
      this.getList();
    },
    handlePageSize(size) {
      this.queryParams.pageSize = size;
      this.queryParams.pageNo = 1;
      this.getList();
    },
    getList() {
      if (!this.isAuth("equipmentoperation:list")) {
        return this.$message.warning("没有权限!");
      }
      this.$http({
        url: this.$http.adornUrl("/equipmentoperation/list"),
        method: "get",
        params: this.$http.adornParams({
          operator: this.queryParams.operator,
          type: this.queryParams.type == -1 ? "" : this.queryParams.type,
          carPlateNum: this.queryParams.carPlateNum,
          carPlateColor: this.queryParams.carPlateColor,
          equipmentId: this.queryParams.equipmentId,
          pageNo: this.queryParams.pageNo,
          pageSize: this.queryParams.pageSize,
        }),
      })
        .then((res) => {
          let tableData = res.data?.page?.list || [];
          tableData.forEach((el) => {
            el["_type"] = switchType(el.type);
          });
          this.tableData = tableData;
          this.totalCount = res.data?.page?.totalCount || 0;
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
};
function switchType(type) {
  let _type = "";
  switch (type) {
    case 0:
      _type = "新增";
      break;
    case 1:
      _type = "修改";
      break;
    case 2:
      _type = "删除";
      break;
    case 3:
      _type = "启停";
      break;
    case 4:
      _type = "添加单北斗设备号";
      break;
    case 5:
      _type = "修改单北斗设备号";
      break;
    case 6:
      _type = "清除单北斗设备号";
      break;
    default:
      _type = "-";
      break;
  }
  return _type;
}
</script>

<style></style>
